.Header {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 300;
    padding: .5em 0;
}

    .Header .position {
        padding: 0 1em;
        position: absolute;
        top: 50%;
        font-weight: bold;
        height: 35%;
        opacity: .3;
    }

    .Header .position.left {
        left: 0;
        transform: translateY(-50%) scaleX(-1);
    }

    .Header .position.right {
        right: 0;
        transform: translateY(-50%);
    }