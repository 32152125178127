.CurrentEvent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0 80px;
}



  .CurrentEvent .time {
    font-family: Lora, serif;
    font-size: 34px;
    color: #FFFFFF;

    text-align:left!important;
  }



  .CurrentEvent .title {
    font-family: Euclid, sans-serif;
    font-size: 80px;
    line-height: 1.1;
    margin: 10px 0 30px 0;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5
  }



  .CurrentEvent .title.small {
  font-size: 64px;
}



  .CurrentEvent .booker {
    font-family: Lora, serif;
    font-size: 24px;
    line-height: 1.4;
    color: #FFFFFF;
  }



  .CurrentEvent .booker strong {
      color: white;
      font-weight: inherit;
    }



  .CurrentEvent .actions {
    text-align: left;
    margin-top: 50px;
    transition: all 0.3s ease-out
  }



  .CurrentEvent .actions.is-visible {
  opacity: 1.0;
}



  .CurrentEvent .actions.is-hidden {
  opacity: 0.0;
  visibility: hidden;
}

.Button {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0;
  padding: 23px 65px;
  background-color: black;
  color: white;
  border: 0;
  border-radius: 0;
  font-size: 20px;
  line-height: 1;
  font-family: inherit;
  font-weight: 600;
  letter-spacing: 0.05em;
  outline: none;
  cursor: pointer;
}

.Link {
  margin-top: 40px;
  display: inline-block;
  width: auto;
  color: white;
  font-size: 20px;
  padding: 2px 0;
  line-height: 1.2;
  border-bottom: 2px solid white;
  cursor: pointer;
}
