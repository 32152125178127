@font-face {
  font-family: "Euclid";
  font-display: auto;
  font-weight: 400;
  src: url("/fonts/EuclidCircularA-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Lora";
  font-weight: 400;
  src: url("/fonts/Lora-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lora";
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/Lora-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lora";
  font-weight: 500;
  font-style: italic;
  src: url("/fonts/Lora-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Lora";
  font-weight: 500;
  src: url("/fonts/Lora-Bold.woff2") format("woff2");
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #000000;
  color: #FFFFFF;
  font-family: "Euclid", sans-serif

}

.App.free {
  background:#46CCA0;
}

.App.busy {
  background:#E5236A;
}

.App.loading {
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #FFFFFF;
}

.App.loading::after {
  display: none;
}

.App {
  /* The arrow */
}

.App::after {
  content: "";
  background: url("/images/left.svg") no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  top: calc(50% - 17px);
  left: 18px;
  height: 34px;
  width: 34px;
}

.App.left {
  text-align: left;
}

.App.right {
  text-align: right;
}

.App.right::after {
  content: "";
  background-image: url("/images/right.svg");
  left: auto;
  right: 18px;
}

.App.right .TimelineEvent::before {
      left: auto;
      right: 39px;
}

.App.right .TimelineEvent::after {
      left: auto;
      right: 34px;
}

.right
  .logo {
    width: 10em;
    position: fixed;
    top: 2em;
    left: 80px;
    z-index: 999;
  }

.right .room-name {
  position: fixed;
  top: 3.4em;
  left: 80px;
  z-index: 999;


}

.left
  .logo {
  width: 10rem;
  position: fixed;
  top: 2em;
  right: 80px;
  z-index: 999;
}

.left .room-name {
  position: fixed;

  top: 6.4em;
  right: 80px;
  z-index: 999;
}

