.EventList {
  padding: 2em;
  background: rgba(0, 0, 0, 0.2);
}

  .EventList .EventItem {
    padding: 0.2em 0.5em;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }

  .EventList .EventTime {
    font-weight: 400;
  }
