.Event {
  padding: 2em;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  width: 100%;

}

  .Event .badge {
    display: inline-block;
    padding: 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.1)
  }

  .Event .name {
    margin-bottom: 0.25em;
    font-size: 36px;
  }
