*, *::before, *::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html, body, #app {
  height: 100%;
}

body {
  font-family: Euclid, EuclidCircularA, sans-serif;
  margin: 0;
  padding: 0;
}
