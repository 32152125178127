.TimelineEvent {
  height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgb(255,255,255,0.5);
  padding: 0 80px;
  position: relative;
}

  .TimelineEvent .subtitle {
    font-family: 'Lora', serif;
    font-size: 16px;
    margin-top: 5px;
  }

  .TimelineEvent .title {
    margin-top: 5px;
    font-family: 'Euclid', sans-serif;
    font-size: 30px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

.TimelineEvent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 39px;
  bottom: 0;
  width: 1px;
  border-left: 1px solid rgb(255,255,255,0.5);
}

.TimelineEvent::after {
  content: "";
  position: absolute;
  top: 75px;
  left: 34px;
  width: 11px;
  height: 11px;
  border-radius: 10px;
  background: rgb(255,255,255,0.5);
}
